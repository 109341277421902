<template>
  <transition name="fadeInUp">
    <v-dialog
      class="image_modal"
      v-model="modalIsOpen"
      @click:outside="toggleModal"
    >
      <v-card>
        <img :src="modalImage" alt="Modal Image" width="250" height="250" />
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
export default {
  name: "ImageModal",

  emits: ["toggleModal"],

  props: {
    modalIsOpen: {
      type: Boolean,
      required: true,
    },
    modalImage: {
      type: String,
      required: true,
    },
  },

  methods: {
    toggleModal() {
      this.$emit('toggleModal')
    },
  },

  // watch: {
  //   modalIsOpen(newVal) {
  //     newVal ? this.test = true : this.test = false
  //   }
  // },

  // data() {
  //   return {
  //     test: this.modalIsOpen,
  //   }
  // },
};
</script>
