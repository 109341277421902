export default class Permission {
  setControls(path) {
    let status;
    if (localStorage.getItem("saloon_dash_User_Type") != "superadmin") {
      if (localStorage.getItem("saloon_dash_controls")) {
        let controls = JSON.parse(localStorage.getItem("saloon_dash_controls"));
        const found = controls.find((control) => control.url == path);

        if (found) {
          status = false;
        } else {
          status = true;
        }
      }
    } else {
      status = false;
    }
    return status;
  }
  allowed(path) {
    let status;
    if (localStorage.getItem("saloon_dash_User_Type") != "superadmin") {
      if (localStorage.getItem("saloon_dash_permissions")) {
        let controls = JSON.parse(
          localStorage.getItem("saloon_dash_permissions").split(",")
        );
        console.log(path);
        console.log(controls);

        const found = controls.find((control) => control.url == path);
        console.log(found);
        if (found) {
          status = false;
        } else {
          status = true;
        }
      }
    } else {
      status = false;
    }
    return status;
  }
}
