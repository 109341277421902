<template>
  <div>
    <Breadcrumb :items="items" />
    <Loader v-if="loading && editMode"></Loader>
    <div v-else>
      <noData v-if="noData" />
      <div class="form countryForm custom_card" v-else>
        <ValidationObserver ref="formCoupon" @submit.prevent="submit">
          <div class="row">
            <!-- Start:: code -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.code')"
              class="col-lg-4"
              rules="required|min:2|max:250"
            >
              <base-input
                :name="$t('labels.code')"
                type="text"
                :placeholder="$t('labels.code')"
                v-model="data.code"
              />
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: code -->

            <!-- Start:: discount_type -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.discount_type')"
              class="col-lg-4"
              rules="required|min:2|max:250"
            >
              <base-select-input
                :name="$t('labels.discount_type')"
                v-model="data.discount_type"
                :static="true"
                :staticItems="discountTypes"
                :placeholder="$t('labels.discount_type')"
              ></base-select-input>
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: discount_type -->

            <!-- Start:: DiscountAmount -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="`${
                data.discount_type ? data.discount_type?.name : $t('select')
              } ${$t('discound')}`"
              class="col-lg-4"
              :rules="`required|min_value:1|${
                data.discount_type?.id == 'percentage' ? 'max_value:100' : ''
              }`"
            >
              <base-input
                :name="`${
                  data.discount_type ? data.discount_type.name : $t('select')
                } ${$t('discound')}`"
                type="number"
                @keypress="preventMinus"
                :placeholder="`${
                  data.discount_type ? data.discount_type.name : $t('select')
                } ${$t('discound')}`"
                v-model.trim="data.amount"
              />
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: DiscountAmount -->

            <!-- Start:: UsageTimes -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.usage_times')"
              class="col-lg-4"
              rules="required|min_value:1"
            >
              <base-input
                :name="$t('labels.usage_times')"
                type="number"
                @keypress="preventMinus"
                :placeholder="$t('labels.usage_times')"
                v-model.number="data.usage_count"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: UsageTimes -->

            <!-- Start:: UserUsageTimes -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.min_purchase')"
              class="col-lg-4"
              :rules="`required`"
            >
              <base-input
                :name="$t('labels.min_purchase')"
                type="number"
                @keypress="preventMinus"
                :placeholder="$t('labels.min_purchase')"
                v-model.number="data.min_purchase"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: UserUsageTimes -->

            <!-- Start:: UserUsageTimes -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="`
                data.discount_type`"
              class="col-lg-4"
              :rules="`required`"
            >
              <base-input
                :name="`${
                  data.discount_type
                    ? $t('labels.max_amount', { name: data.discount_type.name })
                    : $t('labels.max_amount', { name: $t('monyAmount') })
                }`"
                type="number"
                @keypress="preventMinus"
                :placeholder="`${
                  data.discount_type
                    ? $t('labels.max_amount', { name: data.discount_type.name })
                    : $t('labels.max_amount', { name: $t('monyAmount') })
                }`"
                v-model.number="data.max_amount"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: UserUsageTimes -->

            <!-- Start::  Start At -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.start_at')"
              class="col-lg-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.start_at')"
                type="date"
                :allowedDate="new Date()"
                v-model="data.start_at"
                :acceptToUp="true"
                :placeholder="$t('labels.start_at')"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>

            <!-- Start::  end At -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.end_at')"
              class="col-lg-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.end_at')"
                type="date"
                v-model="data.end_at"
                :acceptToUp="true"
                :allowedDate="data.start_at ? data.start_at : new Date()"
                :placeholder="$t('labels.end_at')"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>

            <!-- Start:: Checks -->
            <div class="col-lg-12 py-0">
              <div class="divider">
                <h4>{{ $t("labels.status") }}</h4>
                <hr
                  role="separator"
                  aria-orientation="horizontal"
                  class="v-divider theme--dark"
                />
              </div>
            </div>
            <v-checkbox
              v-model="data.is_active"
              :label="$t('labels.is_active')"
            ></v-checkbox>
          </div>

          <div class="button_wrapper center">
            <v-btn
              type="submit"
              @click="submit"
              :disabled="loading"
              :loading="loading"
            >
              {{ $t("submit") }}
            </v-btn>
          </div>
          <!-- <base-button type="submit" @click="submit" class="center"></base-button> -->
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
// import MapWrite from "../../../../components/Maps/MapWrite.vue";

export default {
  // components: { MapWrite },
  props: ["id"],
  data() {
    return {
      noData: false,
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.coupons.title"),
          disabled: false,
          href: "/coupons/show-all",
        },
        {
          text: this.$t(`breadcrumb.coupons.${this.id ? "edit" : "add"}`),
          disabled: true,
          href: "",
        },
      ],
      loading: false,
      data: {
        code: null,
        start_at: null,
        start_time: null,
        end_at: null,
        max_amount: null,
        end_time: null,
        usage_count: null,
        min_purchase: null,
        discount_type: null,
        user_type: null,
        amount: null,
        is_active: false,
        discountAppliedToJourney: "",
      },
      discountTypes: [
        {
          id: "amount",
          name: this.$t("monyAmount"),
        },
        {
          id: "percentage",
          name: this.$t("percentage", { name: " " }),
        },
      ],
    };
  },
  methods: {
    getEditData() {
      this.noData = false;
      this.loading = true;
      this.axios({
        method: "GET",
        url: `coupons/${this.id}`,
      })
        .then((res) => {
          this.loading = false;

          this.data = {
            code: res.data.data.code,
            discount_type: this.discountTypes.find((item) => {
              return item.id == res.data.data.discount_type;
            }),
            amount: res.data.data.coupon_amount,
            max_amount: res.data.data.max_discount,
            usage_count: res.data.data.max_used,
            min_purchase: res.data.data.min_purchase,
            start_at: new Date(res.data.data.start_at).toLocaleDateString(
              "en-CA"
            ),
            end_at: new Date(res.data.data.expired_at).toLocaleDateString(
              "en-CA"
            ),

            // Checks
            is_active: res.data.data.is_active,
          };
        })
        .catch((err) => {
          this.$iziToast.error({
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
          this.noData = true;
          this.loading = false;
        });
    },
    async uploadImage(event, modal, varName) {
      if (event) {
        this.btnLoading = true;
        const data = new FormData();
        data.append("file", event);
        data.append("attachment_type", "image");
        data.append("model", modal);

        await this.axios({
          method: "POST",
          url: "attachments",
          data: data,
        })
          .then((res) => {
            this.data[varName].image = res.data.data;
            this.data[varName].preview = URL.createObjectURL(event);
            this.btnLoading = false;
          })
          .catch((err) => {
            this.$iziToast.error({
              title: this.$t("validation.error"),
              message: err.response.data.message,
            });

            this.btnLoading = false;
          });
      }
    },
    submit() {
      this.$refs.formCoupon.validate().then((d) => {
        if (d) {
          this.loading = true;
          const data = new FormData();
          data.append(
            "start_at",
            `${this.$moment(this.data.start_at).format("YYYY-MM-DD")}`
          );
          data.append(
            "expired_at",
            `${this.$moment(this.data.end_at).format("YYYY-MM-DD")}`
          );
          data.append("max_used", this.data.usage_count);
          data.append("min_purchase", this.data.min_purchase);
          data.append("discount_type", this.data.discount_type?.id);
          data.append("code", this.data.code);
          data.append("max_discount", this.data.max_amount);
          data.append("coupon_amount", this.data.amount);
          data.append("is_active", +this.data.is_active);

          if (this.id) {
            data.append("_method", "PUT");
          }
          let url = "";
          if (this.id) {
            url = `coupons/${this.id}`;
          } else {
            url = "coupons";
          }

          let message = this.id
            ? this.$t("editSuccess")
            : this.$t("addSuccess");

          this.axios({
            method: "POST",
            url: url,
            data: data,
          })
            .then(() => {
              this.$iziToast.success({
                title: this.$t("success"),
                message: message,
              });
              this.loading = false;
              this.$router.push("/coupons/show-all");
            })
            .catch((err) => {
              this.loading = false;
              this.$iziToast.error({
                title: this.$t("error"),
                message:
                  err.response.data.message ?? err.response.data.messages,
              });
            });
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      });
    },
  },
  computed: {
    editMode() {
      return !!this.id;
    },
  },

  created() {
    this.control = this.$permission.setControls(this.$route.path);
    if (this.id) {
      this.getEditData();
    }
  },
};
</script>
