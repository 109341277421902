<template>
  <div>
    <v-switch
      :disabled="loading || disabled"
      :loading="loading"
      :color="type == 'active' ? '#26BE00' : '#ed143d'"
      v-model="
        item[value == 'toggle-active-user' ? 'is_admin_active_user' : value]
      "
      @mousedown="confirmSwith(item, value, $event)"
    ></v-switch>
    <!-- Start Ban Model -->
    <base-model
      @close="model_1.show_model = false"
      :show="model_1.show_model"
      name="fadeIn"
    >
      <form @submit.prevent="sendReply">
        <div class="row p-5 text-center">
          <h3 class="h_3">سبب الحظر</h3>

          <!-- Start:: Reply -->
          <base-input
            col="12"
            type="textarea"
            :placeholder="$t('labels.reason')"
            :required="true"
            v-model="model_1.reason"
          />
          <!-- End:: Reply -->
        </div>

        <base-button :loading="sendReply_loading" class="center">
          {{ $t("submit") }}
        </base-button>
      </form>
    </base-model>
    <!-- End Ban Modal -->

    <!-- Start Change Provider Model -->
    <base-model
      @close="model_2.show_model = false"
      :show="model_2.show_model"
      name="fadeIn"
    >
      <ValidationObserver ref="formProviders" @submit.prevent="sendReply2">
        <div class="row p-5 text-center">
          <h3 class="h_3">ادخل البيانات التاليه</h3>

          <!-- Start::  Start At -->
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.start_at')"
            class="col-lg-12"
            rules="required"
          >
            <base-picker-input
              :name="$t('labels.start_at')"
              type="date"
              :allowedDate="new Date()"
              v-model="model_2.start_date"
              :placeholder="$t('labels.start_at')"
            />

            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>

          <!-- Start::  end At -->
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.end_at')"
            class="col-lg-12"
            rules="required"
          >
            <base-picker-input
              :name="$t('labels.end_at')"
              type="date"
              v-model="model_2.end_date"
              :placeholder="$t('labels.end_at')"
            />

            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.price')"
            class="col-lg-12"
            rules="required"
          >
            <base-input
              :name="$t('labels.price')"
              type="text"
              :placeholder="$t('labels.price')"
              v-model="model_2.price"
            />
            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </div>

        <div class="button_wrapper center">
          <v-btn
            type="submit"
            @click="sendReply2"
            :disabled="sendReply_loading2"
            :loading="sendReply_loading2"
          >
            {{ $t("submit") }}
          </v-btn>
        </div>
      </ValidationObserver>
    </base-model>
    <!-- End Ban Modal -->

    <!-- Start Change Provider Model -->
    <base-model
      @close="model_3.show_model = false"
      :show="model_3.show_model"
      name="fadeIn"
    >
      <ValidationObserver ref="formProviders" @submit.prevent="sendReply3">
        <div class="row p-5 text-center">
          <h3 class="h_3">ادخل البيانات التاليه</h3>

          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.app_amount_type')"
            class="col-lg-12"
            rules="required"
          >
            <base-select-input
              :name="$t('labels.app_amount_type')"
              v-model="model_3.app_amount_type"
              :static="true"
              :staticItems="amountTypes"
              :placeholder="$t('labels.app_amount_type')"
            ></base-select-input>
            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.price')"
            class="col-lg-12"
            rules="required"
          >
            <base-input
              :name="$t('labels.price')"
              type="text"
              :placeholder="$t('labels.price')"
              v-model="model_3.app_amount_price"
            />
            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </div>

        <div class="button_wrapper center">
          <v-btn
            type="submit"
            @click="sendReply3"
            :disabled="sendReply_loading3"
            :loading="sendReply_loading3"
          >
            {{ $t("submit") }}
          </v-btn>
        </div>
      </ValidationObserver>
    </base-model>
    <!-- End Ban Modal -->
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      required: false,
    },
    name: {
      required: true,
    },
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      sendReply_loading: false,
      sendReply_loading2: false,
      sendReply_loading3: false,
      model_1: {
        show_model: false,
        reason: null,
      },
      model_2: {
        show_model: false,
        start_date: null,
        end_date: null,
        price: null,
      },
      model_3: {
        show_model: false,
        app_amount_type: null,
        app_amount_price: null,
      },
      amountTypes: [
        {
          id: "amount",
          name: this.$t("labels.amount"),
        },
        {
          id: "percentage",
          name: this.$t("labels.percentage"),
        },
      ],
    };
  },
  methods: {
    show_model_1() {
      this.model_1.show_model = true;
    },
    show_model_2() {
      this.model_2.show_model = true;
    },
    show_model_3() {
      this.model_3.show_model = true;
    },
    // ===== Delete
    confirmSwith(item, value, event) {
      event.preventDefault();
      // Start:: Sweet Alert  Modal
      this.$swal({
        title: this.$t("areYouSure"),
        //   text: this.$t("youWillNotBeAbleToGoBackInTheProcess"),

        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("confirm"),
      }).then((result) => {
        console.log(result);
        console.log(item, value, event);
        if (result.isConfirmed) {
          if (value == "is_ban" && item.is_ban == false) {
            this.show_model_1();
          } else if (
            value == "is_admin_active_user" &&
            this.name == "providers" &&
            item.is_admin_active_user == false
          ) {
            this.show_model_2();
          } else if (
            value == "is_active" &&
            this.name == "provider-services" &&
            item.is_active == false
          ) {
            this.show_model_3();
          } else {
            this.SwitchToggle(item, value);
          }
        }
      });
      // End:: Sweet Alert  Modal
    },

    sendReply() {
      this.sendReply_loading = true;
      const frmData = new FormData();
      frmData.append("is_ban", this.item.is_ban == 0 ? 1 : 0);
      frmData.append("ban_reason", this.model_1.reason);
      frmData.append("_method", "PUT");
      this.axios({
        method: "post",
        url: `clients/${this.item.id}/is_ban`,
        data: frmData,
      })
        .then(() => {
          this.sendReply_loading = false;
          this.model_1.show_model = false;
          this.$iziToast.success({
            title: this.$t("success"),
            message: "تم التعديل بنجاح",
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$iziToast.error({
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
        });
    },

    sendReply2() {
      this.$refs.formProviders.validate().then((d) => {
        if (d) {
          this.sendReply_loading2 = true;
          const frmData = new FormData();
          frmData.append("start_date", this.model_2.start_date);
          frmData.append("end_date", this.model_2.end_date);
          frmData.append("price", this.model_2.price);
          frmData.append("is_admin_active_user", 1);
          frmData.append("_method", "PUT");
          this.axios({
            method: "post",
            url: `providers/is_active/${this.item.id}`,
            data: frmData,
          })
            .then(() => {
              this.sendReply_loading2 = false;
              this.model_2.show_model = false;
              location.reload();
              this.$iziToast.success({
                title: this.$t("success"),
                message: "تم التعديل بنجاح",
              });
            })
            .catch((err) => {
              this.sendReply_loading2 = false;
              this.$iziToast.error({
                title: this.$t("error"),
                message:
                  err.response?.data.message ?? err.response?.data.messages,
              });
            });
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      });
    },

    sendReply3() {
      console.log(this.item);
      this.$refs.formProviders.validate().then((d) => {
        if (d) {
          this.sendReply_loading3 = true;
          const frmData = new FormData();
          frmData.append("app_amount_type", this.model_3.app_amount_type?.id);
          frmData.append("app_amount_price", this.model_3.app_amount_price);
          frmData.append("is_active", 1);
          // frmData.append("_method", "PUT");
          this.axios({
            method: "post",
            url: `provider-services/${this.item.id}/is-available`,
            data: frmData,
          })
            .then(() => {
              this.sendReply_loading3 = false;
              this.model_3.show_model = false;
              this.$iziToast.success({
                title: this.$t("success"),
                message: "تم التعديل بنجاح",
              });
            })
            .catch((err) => {
              this.sendReply_loading3 = false;
              this.$iziToast.error({
                title: this.$t("error"),
                message:
                  err.response?.data.message ?? err.response?.data.messages,
              });
            });
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      });
    },

    SwitchToggle(item, value) {
      this.loading = true;
      const frmData = new FormData();
      if (this.name != "provider-services") {
        frmData.append("_method", "PUT");
      }
      // frmData.append("ban_reason", "test");
      // // is_admin_active_user

      frmData.append(
        value == "is_admin_active_user" ? "is_active" : value,
        +item[value] == 0 ? 1 : 0
      );

      if (
        value == "is_admin_active_user" &&
        this.name == "providers" &&
        item.is_admin_active_user
      ) {
        frmData.append(
          "is_admin_active_user",
          +item.is_admin_active_user == 0 ? 1 : 0
        );
      }
      // if (
      //   value == "is_active" &&
      //   this.name == "provider-services" &&
      //   item.is_active
      // ) {
      //   frmData.append("is_admin_active_user", +item.is_active == 0 ? 1 : 0);
      // }

      let url = "";
      let message = "";

      if (value == "is_locked") {
        url = `${this.name}/${item.id}/update-locked`;
        message =
          item.is_locked == 1 ? "تم  الغاء حجب الحساب" : "تم  حجب الحساب";
      } else if (value == "is_active" && this.name == "users") {
        url = `${this.name}/${item.id}/toggle-active-user`;
        message = item.is_active ? "تم الغاء تفعيل الحساب" : "تم  تفعيل الحساب";
      } else if (value == "is_admin_active_user" && this.name == "providers") {
        url = `${this.name}/is_active/${item.id}`;
        message = item.is_active ? "تم الغاء تفعيل الحساب" : "تم  تفعيل الحساب";
      } else if (value == "is_active" && this.name == "provider-services") {
        url = `${this.name}/${item.id}/is-available`;
        message = item.is_active ? "تم الغاء تفعيل الحساب" : "تم  تفعيل الحساب";
      } else if (value == "is_ban" && this.name == "clients") {
        url = `${this.name}/${item.id}/is_ban`;
        message = item.is_ban == 1 ? "تم  الغاء حظر الحساب" : "تم  حظر الحساب";
      }
      // else if (value == "is_active" && this.name == "providers") {
      //   url = `${this.name}/${item.id}/toggle-active-provider`;
      //   message = item.is_active ? "تم الغاء تفعيل الحساب" : "تم  تفعيل الحساب";
      // }
      else if (value == "is_ban" && this.name == "providers") {
        url = `${this.name}/${item.id}/toggle-ban-provider`;
        message = item.is_ban == 1 ? "تم  الغاء حظر الحساب" : "تم  حظر الحساب";
      } else if (value == "is_admin_active_user") {
        url = `${this.name}/${item.id}/toggle-active-user`;
        message = item.is_admin_active_user
          ? "تم الغاء تفعيل الحساب"
          : "تم  تفعيل الحساب";
      } else if (value == "toggle-verify-comment") {
        url = `comments/${item.id}/toggle-verify-comment`;
        message = item.is_admin_active_user
          ? "تم قبول التعليق"
          : "تم رفض التعليق";
      } else if (value == "toggle-active-user") {
        url = `${this.name}/${item.id}/toggle-active-user`;
        message = item.is_admin_active_user
          ? "تم  الغاء تفعيل الحساب"
          : "تم  تفعيل الحساب";
      }

      this.axios
        .post(url, frmData)
        .then(() => {
          item[value == "toggle-active-user" ? "is_admin_active_user" : value] =
            +item[
              value == "toggle-active-user" ? "is_admin_active_user" : value
            ] == 1
              ? 0
              : 1;
          this.loading = false;
          this.$iziToast.success({
            title: this.$t("success"),
            message: message,
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$iziToast.error({
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
        });
    },
  },
};
</script>

<style></style>
