import axios from "axios";

export default {
  getNotifications(context) {
    axios
      .get(`${process.env.VUE_APP_BASE_URL}notification`, {
        headers: {
          "Accept-Language": "ar",
        },
      })
      .then((res) => {
        context.commit("setNotifications", res.data.data);
        context.commit("setUnread", res.data.unread_count);
      });
  },
};
