export default {
  // ============================= Set_User_Dataa
  set_userId(state, payload) {
    localStorage.setItem("saloon_dash_User_Id", payload);
    state.user.id = payload;
  },
  set_token(state, payload) {
    localStorage.setItem("saloon_dash_Token", payload);
    state.user.token = payload;
  },
  set_userName(state, payload) {
    localStorage.setItem("saloon_dash_User_name", payload);
    state.user.name = payload;
  },
  set_usertype(state, payload) {
    localStorage.setItem("saloon_dash_User_Type", payload);
    state.user.usertype = payload;
  },
  set_userImage(state, payload) {
    localStorage.setItem("saloon_dash_userImg", payload);
    state.user.image = payload;
  },
  set_userPhone(state, payload) {
    localStorage.setItem("saloon_dash_userPhone", payload);
    state.user.phone = payload;
  },
  set_userEmail(state, payload) {
    localStorage.setItem("saloon_dash_userEmail", payload);
    state.user.email = payload;
  },
  set_userGender(state, payload) {
    localStorage.setItem("saloon_dash_userGender", payload);
    state.user.gender = payload;
  },
  saloon_dash_permissions(state, payload) {
    localStorage.setItem("saloon_dash_permissions", payload);
    state.user.permissions = payload;
  },

  // ============================= Remove_User_Dataa
  remove_user_data(state) {
    localStorage.removeItem("saloon_dash_User_Id");
    localStorage.removeItem("saloon_dash_User_name");
    localStorage.removeItem("saloon_dash_Token");
    localStorage.removeItem("saloon_dash_User_Type");
    localStorage.removeItem("saloon_dash_userImg");
    localStorage.removeItem("saloon_dash_userPhone");
    localStorage.removeItem("saloon_dash_userEmail");
    localStorage.removeItem("saloon_dash_permissions");
    state.user.id = null;
    state.user.token = null;
    state.user.image = null;
    state.user.usertype = null;
    state.user.phone = null;
    state.user.email = null;
    state.user.gender = null;
  },
};
