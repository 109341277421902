import Vue from "vue";
import VueRouter from "vue-router";

// ============================== Start Views ==============================
// Home
import Home from "../views/Home.vue";

// ********** Children
import HomePage from "../views/children/HomePage.vue";
// ******* Start Points *******
import PointsHome from "../views/children/CRUDS/Points/Home.vue";
// Show All
import AllPoints from "../views/children/CRUDS/Points/ShowAll.vue";
// Show Single
import PointShow from "../views/children/CRUDS/Points/Show.vue";
// Add/Edit
import PointForm from "../views/children/CRUDS/Points/Form.vue";
// ******* End Points *******

// ******* Start Coupons *******
import CouponsHome from "../views/children/CRUDS/Coupons/Home.vue";
// Show All
import AllCoupons from "../views/children/CRUDS/Coupons/ShowAll.vue";
// Show Single
import CouponShow from "../views/children/CRUDS/Coupons/Show.vue";
// Add/Edit
import CouponForm from "../views/children/CRUDS/Coupons/Form.vue";
// ******* End Coupons *******

// ******* Start Cancel Reasons *******
import CancelReasonsHome from "../views/children/CRUDS/CancelReasons/Home.vue";
// Show All
import AllCancelReasons from "../views/children/CRUDS/CancelReasons/ShowAll.vue";
// Show Single
import CancelReasonShow from "../views/children/CRUDS/CancelReasons/Show.vue";
// Add/Edit
import CancelReasonForm from "../views/children/CRUDS/CancelReasons/Form.vue";
// ******* End Cancel Reasons *******

// ========== Start Profiles ==========

// ========== End Profiles ==========

// ============================== START:: CRUDS ==============================

// ========== Start:: Static Screens ==========
// import StaticScreens from "../views/children/StaticScreens/Home.vue";
// ========== End:: Static Screens ==========

// ========== Start:: Reference ==========
// import Reference from "../Reference/Reference.vue";
// ========== End:: Reference ==========

// ********** Auth
import Login from "../views/Auth/Login.vue";

// ********** NotFound
import NotFound from "../views/NotFound.vue";
// ============================== End Views ==============================

// Vuex store
import store from "../store/index";
let router = null;
window.rootRouter = router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // Main
    {
      path: "/",
      component: Home,
      children: [
        {
          path: "/",
          name: "HomePage",
          component: HomePage,
        },
        // ============================== Start CRUDS ==============================
        // ******* Start Countries *******
        {
          path: "/countries",
          component: () => import("../views/children/CRUDS/Countries/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllCountries",
              component: () =>
                import("../views/children/CRUDS/Countries/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "CountryShow",
              component: () =>
                import("../views/children/CRUDS/Countries/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "CountryEdit",
              component: () =>
                import("../views/children/CRUDS/Countries/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "CountryAdd",
              component: () =>
                import("../views/children/CRUDS/Countries/Form.vue"),
            },
          ],
        },
        // ******* End Countries *******
        // ******* Start Cities *******
        {
          path: "/cities",
          component: () => import("../views/children/CRUDS/Cities/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllCities",
              component: () =>
                import("../views/children/CRUDS/Cities/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "CityShow",
              component: () =>
                import("../views/children/CRUDS/Cities/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "CityEdit",
              component: () =>
                import("../views/children/CRUDS/Cities/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "CityAdd",
              component: () =>
                import("../views/children/CRUDS/Cities/Form.vue"),
            },
          ],
        },
        // ******* End Cities *******
        // ******* Start Privacy *******
        {
          path: "/privacy",
          component: () => import("../views/children/CRUDS/Privacy/Home.vue"),
          children: [
            // Show All
            // {
            //   path: "show-all",
            //   name: "AllPrivacy",
            //   component: () =>
            //     import("../views/children/CRUDS/Privacy/ShowAll.vue"),
            // },
            // Show Single
            // {
            //   path: "show/:id",
            //   name: "CityShow",
            //   component: () =>
            //     import("../views/children/CRUDS/Privacy/Show.vue"),
            //   props: true,
            // },
            // Edit
            {
              path: "edit/:id",
              name: "CityEdit",
              component: () =>
                import("../views/children/CRUDS/Privacy/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "CityAdd",
              component: () =>
                import("../views/children/CRUDS/Privacy/Form.vue"),
            },
          ],
        },
        // ******* End Privacy *******
        // ******* Start Terms *******
        {
          path: "/terms",
          component: () => import("../views/children/CRUDS/Terms/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllTerms",
              component: () =>
                import("../views/children/CRUDS/Terms/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "CityShow",
              component: () => import("../views/children/CRUDS/Terms/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "CityEdit",
              component: () => import("../views/children/CRUDS/Terms/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "CityAdd",
              component: () => import("../views/children/CRUDS/Terms/Form.vue"),
            },
          ],
        },
        // ******* End Terms *******

        // ******* Start Faqs *******
        {
          path: "/faqs",
          component: () => import("../views/children/Settings/Faq/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "faqs",
              component: () =>
                import("../views/children/Settings/Faq/ShowAll.vue"),
            },
            // Show Single
            // {
            //   path: "show/:id",
            //   name: "faqsShow",
            //   component: () =>
            //     import("../views/children/Settings/Faq/Show.vue"),
            //   props: true,
            // },
            // Edit
            {
              path: "edit/:id",
              name: "faqsEdit",
              component: () =>
                import("../views/children/Settings/Faq/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "faqsAdd",
              component: () =>
                import("../views/children/Settings/Faq/Form.vue"),
            },
          ],
        },
        // ******* End Faqs *******
        // ******* Start Contacts *******
        {
          path: "/contacts",
          component: () => import("@/views/children/CRUDS/Contacts/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllContacts",
              component: () =>
                import("@/views/children/CRUDS/Contacts/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "ContactShow",
              component: () =>
                import("@/views/children/CRUDS/Contacts/Show.vue"),
              props: true,
            },
          ],
        },
        // ******* End Contacts *******
        {
          path: "/recruitments",
          component: () =>
            import("../views/children/CRUDS/Recruitments/Home.vue"),
          children: [
            // Show Single
            {
              path: "show-all",
              name: "recruitmentsShow",
              component: () =>
                import("../views/children/CRUDS/Recruitments/ShowAll.vue"),
              props: true,
            },
            {
              path: "show/:id",
              name: "recruitmentShow",
              component: () =>
                import("../views/children/CRUDS/Recruitments/Show.vue"),
              props: true,
            },
          ],
        },
        {
          path: "/partnership_requests",
          component: () =>
            import("../views/children/CRUDS/PartnershipRequests/ShowAll.vue"),
          children: [
            // Show Single
            {
              path: "show-all",
              name: "partnershipRequestsShow",
              component: () =>
                import(
                  "../views/children/CRUDS/PartnershipRequests/ShowAll.vue"
                ),
              props: true,
            },
          ],
        },
        {
          path: "/orders",
          component: () => import("../views/children/CRUDS/Orders/Home.vue"),
          children: [
            // Show Single
            {
              path: "show-all",
              name: "ordersShowAll",
              component: () =>
                import("../views/children/CRUDS/Orders/ShowAll.vue"),
            },
            {
              path: "today_orders",
              name: "TodayOrders",
              component: () =>
                import("../views/children/CRUDS/Orders/ShowAll.vue"),
            },
            {
              path: "show/:id",
              name: "ordersShow",
              component: () =>
                import("../views/children/CRUDS/Orders/Show.vue"),
              props: true,
            },
          ],
        },
        {
          path: "/request_join",
          component: () =>
            import("../views/children/CRUDS/RequestJoin/Home.vue"),
          children: [
            // Show Single
            {
              path: "show-all",
              name: "requestJoinShowAll",
              component: () =>
                import("../views/children/CRUDS/RequestJoin/ShowAll.vue"),
            },
            // {
            //   path: "accepted?status=''",
            //   name: "requestJoinAcceptedShowAll",
            //   component: () =>
            //     import("../views/children/CRUDS/RequestJoin/ShowAll.vue"),
            // },
            // {
            //   path: "rejected",
            //   name: "requestJoinRejectedShowAll",
            //   component: () =>
            //     import("../views/children/CRUDS/RequestJoin/ShowAll.vue"),
            // },
            {
              path: "show/:id",
              name: "requestJoinShow",
              component: () =>
                import("../views/children/CRUDS/RequestJoin/Show.vue"),
              props: true,
            },
          ],
        },
        {
          path: "/chat_request",
          component: () =>
            import("../views/children/CRUDS/ChatRequest/Home.vue"),
          children: [
            // Show Single
            {
              path: "show-all",
              name: "chatRequestShowAll",
              component: () =>
                import("../views/children/CRUDS/ChatRequest/ShowAll.vue"),
            },
            {
              path: "show/:id/chat/:chat_id",
              name: "chatRequestShow",
              component: () =>
                import("../views/children/CRUDS/ChatRequest/Show.vue"),
              props: true,
            },
          ],
        },
        {
          path: "/subscribers",
          component: () =>
            import("../views/children/CRUDS/Subscribers/ShowAll.vue"),
          children: [
            // Show Single
            {
              path: "show-all",
              name: "subscriptionShow",
              component: () =>
                import("../views/children/CRUDS/Subscribers/ShowAll.vue"),
              props: true,
            },
          ],
        },
        // ******* Start Users *******
        {
          path: "/users",
          component: () => import("../views/children/CRUDS/Users/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllUsers",
              component: () =>
                import("../views/children/CRUDS/Users/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "UserShow",
              component: () => import("../views/children/CRUDS/Users/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "UserEdit",
              component: () => import("../views/children/CRUDS/Users/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "UserAdd",
              component: () => import("../views/children/CRUDS/Users/Form.vue"),
            },
          ],
        },
        // ******* End Users *******
        // ******* Start Hedayat *******
        {
          path: "/hedayat",
          component: () => import("../views/children/CRUDS/Hedayat/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllHedayat",
              component: () =>
                import("../views/children/CRUDS/Hedayat/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "HedayatShow",
              component: () =>
                import("../views/children/CRUDS/Hedayat/Show.vue"),
              props: true,
            },
          ],
        },
        // ******* End Hedayat *******
        {
          path: "/comments",
          component: () => import("../views/children/CRUDS/Comments/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllComments",
              component: () =>
                import("../views/children/CRUDS/Comments/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "CommentsShow",
              component: () =>
                import("../views/children/CRUDS/Comments/Show.vue"),
              props: true,
            },
          ],
        },
        // ******* End Hedayat *******
        // ******* Start Providers *******
        {
          path: "/providers",
          component: () => import("../views/children/CRUDS/Providers/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllProviders",
              component: () =>
                import("../views/children/CRUDS/Providers/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "ProviderShow",
              component: () =>
                import("../views/children/CRUDS/Providers/Show.vue"),
              props: true,
              children: [
                {
                  path: "cards",
                  name: "Cards",
                  component: () =>
                    import(
                      "../views/children/CRUDS/Providers/ProviderDetails/Cards.vue"
                    ),
                  props: true,
                },
                {
                  path: "personal",
                  name: "PersonalData",
                  component: () =>
                    import(
                      "../views/children/CRUDS/Providers/ProviderDetails/Personal.vue"
                    ),
                  props: true,
                },
                {
                  path: "orders",
                  name: "ProvidersOrders",
                  component: () =>
                    import(
                      "../views/children/CRUDS/Providers/ProviderDetails/Orders.vue"
                    ),
                  props: true,
                },
                {
                  path: "employess",
                  name: "ProvidersEmployess",
                  component: () =>
                    import(
                      "../views/children/CRUDS/Providers/ProviderDetails/Employess.vue"
                    ),
                  props: true,
                },
                {
                  path: "services",
                  name: "ProvidersServices",
                  component: () =>
                    import(
                      "../views/children/CRUDS/Providers/ProviderDetails/ProvidersServices.vue"
                    ),
                  props: true,
                },
                {
                  path: "reviews",
                  name: "ProvidersReviews",
                  component: () =>
                    import(
                      "../views/children/CRUDS/Providers/ProviderDetails/Reviews.vue"
                    ),
                  props: true,
                },
              ],
            },
            // Edit
            {
              path: "edit/:id",
              name: "ProviderEdit",
              component: () =>
                import("../views/children/CRUDS/Providers/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "ProviderAdd",
              component: () =>
                import("../views/children/CRUDS/Providers/Form.vue"),
            },
          ],
        },
        // ******* End Providers *******
        // ******* Start Categories *******
        // {
        //   path: "/categories",
        //   component: () => import("@/views/children/CRUDS/Categories/Home.vue"),
        //   children: [
        //     // Show All
        //     {
        //       path: "show-all",
        //       name: "AllCategories",
        //       component: () =>
        //         import("@/views/children/CRUDS/Categories/ShowAll.vue"),
        //     },
        //     // Show Single
        //     {
        //       path: "show/:id",
        //       name: "categoryShow",
        //       component: () =>
        //         import("@/views/children/CRUDS/Categories/Show.vue"),
        //       props: true,
        //     },
        //     // Edit
        //     {
        //       path: "edit/:id",
        //       name: "categoryEdit",
        //       component: () =>
        //         import("@/views/children/CRUDS/Categories/Form.vue"),
        //       props: true,
        //     },
        //     // Add
        //     {
        //       path: "add",
        //       name: "categoryAdd",
        //       component: () =>
        //         import("@/views/children/CRUDS/Categories/Form.vue"),
        //     },
        //   ],
        // },
        // ******* End Categories *******
        // ******* Start Sliders *******
        {
          path: "/sliders",
          component: () => import("@/views/children/CRUDS/Sliders/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllSliders",
              component: () =>
                import("@/views/children/CRUDS/Sliders/ShowAll.vue"),
            },
            // Edit
            {
              path: "edit/:id",
              name: "sliderEdit",
              component: () =>
                import("@/views/children/CRUDS/Sliders/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "sliderAdd",
              component: () =>
                import("@/views/children/CRUDS/Sliders/Form.vue"),
            },
          ],
        },
        // ******* End Sliders *******
        // ******* Start Univeristes *******
        {
          path: "/universities",
          component: () =>
            import("@/views/children/CRUDS/Universities/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllUniversities",
              component: () =>
                import("@/views/children/CRUDS/Universities/ShowAll.vue"),
            },
            // Show Single
            // {
            //   path: "show/:id",
            //   name: "Universitieshow",
            //   component: () =>
            //     import("@/views/children/CRUDS/Universities/Show.vue"),
            //   props: true,
            // },
            // Edit
            {
              path: "edit/:id",
              name: "universitiesEdit",
              component: () =>
                import("@/views/children/CRUDS/Universities/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "universitiesAdd",
              component: () =>
                import("@/views/children/CRUDS/Universities/Form.vue"),
            },
          ],
        },
        // ******* End Univeristes *******
        // ******* Start Group Services *******
        {
          path: "/group_services",
          component: () =>
            import("@/views/children/CRUDS/GroupServices/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllGroupServices",
              component: () =>
                import("@/views/children/CRUDS/GroupServices/ShowAll.vue"),
            },
            // Show Single
            // {
            //   path: "show/:id",
            //   name: "Serviceshow",
            //   component: () =>
            //     import("@/views/children/CRUDS/Services/Show.vue"),
            //   props: true,
            // },
            // Edit
            {
              path: "edit/:id",
              name: "GroupServicesEdit",
              component: () =>
                import("@/views/children/CRUDS/GroupServices/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "GroupServicesAdd",
              component: () =>
                import("@/views/children/CRUDS/GroupServices/Form.vue"),
            },
          ],
        },
        // ******* End Group Services *******
        // ******* Start Services *******
        {
          path: "/services",
          component: () => import("@/views/children/CRUDS/Services/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllServices",
              component: () =>
                import("@/views/children/CRUDS/Services/ShowAll.vue"),
            },
            // Show Single
            // {
            //   path: "show/:id",
            //   name: "Serviceshow",
            //   component: () =>
            //     import("@/views/children/CRUDS/Services/Show.vue"),
            //   props: true,
            // },
            // Edit
            {
              path: "edit/:id",
              name: "ServicesEdit",
              component: () =>
                import("@/views/children/CRUDS/Services/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "ServicesAdd",
              component: () =>
                import("@/views/children/CRUDS/Services/Form.vue"),
            },
          ],
        },
        // ******* End Services *******
        // ******* Start About *******
        {
          path: "/about",
          component: () => import("@/views/children/CRUDS/About/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllAbout",
              component: () =>
                import("@/views/children/CRUDS/About/ShowAll.vue"),
            },
            // Show Single
            // {
            //   path: "show/:id",
            //   name: "Abouthow",
            //   component: () =>
            //     import("@/views/children/CRUDS/About/Show.vue"),
            //   props: true,
            // },
            // Edit
            {
              path: "edit/:id",
              name: "AboutEdit",
              component: () => import("@/views/children/CRUDS/About/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "AboutAdd",
              component: () => import("@/views/children/CRUDS/About/Form.vue"),
            },
          ],
        },
        // ******* End About *******
        // ******* Start About *******
        {
          path: "/pages",
          component: () => import("@/views/children/CRUDS/Pages/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllPages",
              component: () =>
                import("@/views/children/CRUDS/Pages/ShowAll.vue"),
            },
            // Show Single
            // {
            //   path: "show/:id",
            //   name: "Pageshow",
            //   component: () =>
            //     import("@/views/children/CRUDS/Pages/Show.vue"),
            //   props: true,
            // },
            // Edit
            {
              path: "edit/:id",
              name: "PagesEdit",
              component: () => import("@/views/children/CRUDS/Pages/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "PagesAdd",
              component: () => import("@/views/children/CRUDS/Pages/Form.vue"),
            },
          ],
        },
        // ******* End Pages *******
        // ******* Start About *******
        {
          path: "/jobs",
          component: () => import("@/views/children/CRUDS/Jobs/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllJobs",
              component: () =>
                import("@/views/children/CRUDS/Jobs/ShowAll.vue"),
            },
            // Show Single
            // {
            //   path: "show/:id",
            //   name: "Jobshow",
            //   component: () =>
            //     import("@/views/children/CRUDS/Jobs/Show.vue"),
            //   props: true,
            // },
            // Edit
            {
              path: "edit/:id",
              name: "JobsEdit",
              component: () => import("@/views/children/CRUDS/Jobs/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "JobsAdd",
              component: () => import("@/views/children/CRUDS/Jobs/Form.vue"),
            },
          ],
        },
        // ******* End Jobs *******
        // ******* Start WhyChooseUs *******
        {
          path: "/privacies",
          component: () =>
            import("@/views/children/CRUDS/WhyChooseUs/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllWhyChooseUs",
              component: () =>
                import("@/views/children/CRUDS/WhyChooseUs/ShowAll.vue"),
            },
            // Show Single
            // {
            //   path: "show/:id",
            //   name: "WhyChooseUshow",
            //   component: () =>
            //     import("@/views/children/CRUDS/WhyChooseUs/Show.vue"),
            //   props: true,
            // },
            // Edit
            {
              path: "edit/:id",
              name: "WhyChooseUsEdit",
              component: () =>
                import("@/views/children/CRUDS/WhyChooseUs/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "WhyChooseUsAdd",
              component: () =>
                import("@/views/children/CRUDS/WhyChooseUs/Form.vue"),
            },
          ],
        },
        // ******* End WhyChooseUs *******
        // ******* Start HowItWorks *******
        {
          path: "/how_it_works",
          component: () => import("@/views/children/CRUDS/HowItWorks/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllHowItWorks",
              component: () =>
                import("@/views/children/CRUDS/HowItWorks/ShowAll.vue"),
            },
            // Show Single
            // {
            //   path: "show/:id",
            //   name: "HowItWorkshow",
            //   component: () =>
            //     import("@/views/children/CRUDS/HowItWorks/Show.vue"),
            //   props: true,
            // },
            // Edit
            {
              path: "edit/:id",
              name: "HowItWorksEdit",
              component: () =>
                import("@/views/children/CRUDS/HowItWorks/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "HowItWorksAdd",
              component: () =>
                import("@/views/children/CRUDS/HowItWorks/Form.vue"),
            },
          ],
        },
        // ******* End HowItWorks *******
        // ******* Start Blogs *******
        {
          path: "/blogs",
          component: () => import("@/views/children/CRUDS/Blogs/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllBlogs",
              component: () =>
                import("@/views/children/CRUDS/Blogs/ShowAll.vue"),
            },
            // Show Single
            // {
            //   path: "show/:id",
            //   name: "Blogshow",
            //   component: () =>
            //     import("@/views/children/CRUDS/Blogs/Show.vue"),
            //   props: true,
            // },
            // Edit
            {
              path: "edit/:id",
              name: "BlogsEdit",
              component: () => import("@/views/children/CRUDS/Blogs/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "BlogsAdd",
              component: () => import("@/views/children/CRUDS/Blogs/Form.vue"),
            },
          ],
        },
        // ******* End Sliders *******
        // ******* Start Sliders *******
        // {
        //   path: "/service",
        //   component: () => import("@/views/children/CRUDS/Services/Home.vue"),
        //   children: [
        //     // Show All
        //     {
        //       path: "show-all",
        //       name: "AllServices",
        //       component: () =>
        //         import("@/views/children/CRUDS/Services/ShowAll.vue"),
        //     },
        //   ],
        // },
        // ******* End Sliders *******
        // ******* Start Steps *******
        // {
        //   path: "/steps",
        //   component: () => import("@/views/children/CRUDS/Steps/Home.vue"),
        //   children: [
        //     // Show All
        //     {
        //       path: "show-all",
        //       name: "AllSteps",
        //       component: () =>
        //         import("@/views/children/CRUDS/Steps/ShowAll.vue"),
        //     },
        //     // Show Single
        //     {
        //       path: "show/:id",
        //       name: "stepShow",
        //       component: () => import("@/views/children/CRUDS/Steps/Show.vue"),
        //       props: true,
        //     },
        //     // Edit
        //     {
        //       path: "edit/:id",
        //       name: "stepEdit",
        //       component: () => import("@/views/children/CRUDS/Steps/Form.vue"),
        //       props: true,
        //     },
        //     // Add
        //     {
        //       path: "add",
        //       name: "stepAdd",
        //       component: () => import("@/views/children/CRUDS/Steps/Form.vue"),
        //     },
        //   ],
        // },
        // ******* End Steps *******
        // ******* Start Offers *******
        // {
        //   path: "/categories",
        //   component: () => import("@/views/children/CRUDS/Categories/Home.vue"),
        //   children: [
        //     // Show All
        //     {
        //       path: "show-all",
        //       name: "AllCategories",
        //       component: () =>
        //         import("@/views/children/CRUDS/Categories/ShowAll.vue"),
        //     },
        //     // Show Single
        //     {
        //       path: "show/:id",
        //       name: "categoriesShow",
        //       component: () => import("@/views/children/CRUDS/Categories/Show.vue"),
        //       props: true,
        //     },
        //     // Edit
        //     {
        //       path: "edit/:id",
        //       name: "categoriesEdit",
        //       component: () => import("@/views/children/CRUDS/Categories/Form.vue"),
        //       props: true,
        //     },
        //     // Add
        //     {
        //       path: "add",
        //       name: "CategoriesAdd",
        //       component: () => import("@/views/children/CRUDS/Categories/Form.vue"),
        //     },
        //   ],
        // },
        // ******* End Offers *******
        // ******* Start Sales *******
        // {
        //   path: "/subjects",
        //   component: () => import("@/views/children/CRUDS/Subjects/Home.vue"),
        //   children: [
        //     // Show All
        //     {
        //       path: "show-all",
        //       name: "AllSubjects",
        //       component: () =>
        //         import("@/views/children/CRUDS/Subjects/ShowAll.vue"),
        //     },
        //     // Show Single
        //     {
        //       path: "show/:id",
        //       name: "subjectShow",
        //       component: () => import("@/views/children/CRUDS/Subjects/Show.vue"),
        //       props: true,
        //     },
        //     // Edit
        //     {
        //       path: "edit/:id",
        //       name: "subjectEdit",
        //       component: () => import("@/views/children/CRUDS/Subjects/Form.vue"),
        //       props: true,
        //     },
        //     // Add
        //     {
        //       path: "add",
        //       name: "subjectAdd",
        //       component: () => import("@/views/children/CRUDS/Subjects/Form.vue"),
        //     },
        //   ],
        // },
        // ******* End Sales *******
        // ******* Start notifications *******
        {
          path: "/notifications",
          component: () =>
            import("@/views/children/CRUDS/notifications/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "all-notifications",
              component: () =>
                import("@/views/children/CRUDS/notifications/ShowAll.vue"),
            },
            // Show Single

            {
              path: "add",
              name: "add-notification",
              component: () =>
                import("@/views/children/CRUDS/notifications/Form.vue"),
            },
          ],
        },
        // ******* End notifications *******
        // ******* Start Subscribers *******
        {
          path: "/subscribers",
          component: () =>
            import("@/views/children/CRUDS/Subscribers/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllSubscribers",
              component: () =>
                import("@/views/children/CRUDS/Subscribers/ShowAll.vue"),
            },
            // Show Single
            // {
            //   path: "show/:id",
            //   name: "SubscribersShow",
            //   component: () => import("@/views/children/CRUDS/Subscribers/Show.vue"),
            //   props: true,
            // },
          ],
        },
        // ******* End Subscribers *******
        // ******* Start Discounts *******
        // {
        //   path: "/discounts",
        //   component: () => import("@/views/children/CRUDS/Discounts/Home.vue"),
        //   children: [
        //     // Show All
        //     {
        //       path: "show-all",
        //       name: "AllDiscounts",
        //       component: () =>
        //         import("@/views/children/CRUDS/Discounts/ShowAll.vue"),
        //     },
        //     // Show Single
        //     {
        //       path: "show/:id",
        //       name: "DiscountShow",
        //       component: () => import("@/views/children/CRUDS/Discounts/Show.vue"),
        //       props: true,
        //     },
        //   ],
        // },
        // ******* End Discounts *******
        // ******* Start Cancel Reasons *******
        {
          path: "/cancel_reasons",
          component: CancelReasonsHome,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllCancelReasons",
              component: AllCancelReasons,
            },
            // Show Single
            {
              path: "show/:id",
              name: "CancelReasonShow",
              component: CancelReasonShow,
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "CancelReasonEdit",
              component: CancelReasonForm,
              props: true,
            },
            // Add
            {
              path: "add",
              name: "CancelReasonAdd",
              component: CancelReasonForm,
            },
          ],
        },
        // ******* End Cancel Reasons *******
        // ******* Start Points *******
        {
          path: "/points",
          component: PointsHome,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllPoints",
              component: AllPoints,
            },
            // Show Single
            {
              path: "show/:id",
              name: "PointShow",
              component: PointShow,
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "PointEdit",
              component: PointForm,
              props: true,
            },
            // Add
            {
              path: "add",
              name: "PointAdd",
              component: PointForm,
            },
          ],
        },
        // ******* End points *******
        // ******* Start Coupons *******
        {
          path: "/coupons",
          component: CouponsHome,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllCoupons",
              component: AllCoupons,
            },
            // Show Single
            {
              path: "show/:id",
              name: "CouponShow",
              component: CouponShow,
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "CouponEdit",
              component: CouponForm,
              props: true,
            },
            // Add
            {
              path: "add",
              name: "CouponAdd",
              component: CouponForm,
            },
          ],
        },
        // ******* End Coupons *******

        // ******* Start  Admins *******
        {
          path: "/admins",
          component: () => import("@/views/children/CRUDS/Admins/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllAdmins",
              component: () =>
                import("@/views/children/CRUDS/Admins/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "adminShow",
              component: () => import("@/views/children/CRUDS/Admins/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "adminEdit",
              component: () => import("@/views/children/CRUDS/Admins/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "adminAdd",
              component: () => import("@/views/children/CRUDS/Admins/Form.vue"),
            },
          ],
        },
        // ******* End  Admins *******
        // ******* Start  Permission *******
        {
          path: "/permissions",
          component: () =>
            import("@/views/children/CRUDS/Permissions/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllPermissions",
              component: () =>
                import("@/views/children/CRUDS/Permissions/ShowAll.vue"),
            },
            // Show Single

            // Edit
            {
              path: "edit/:id",
              name: "EditPermission",
              component: () =>
                import("@/views/children/CRUDS/Permissions/Form.vue"),
              props: true,
            },
            // Edit
            {
              path: "add",
              name: "AddPermission",
              component: () =>
                import("@/views/children/CRUDS/Permissions/Form.vue"),
            },
          ],
        },
        // ******* End  Permission *******

        // ******* Start  permissions-labels *******
        {
          path: "/permission-labels",
          component: () =>
            import("@/views/children/CRUDS/PermissionLabels/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "all-permission-labels",
              component: () =>
                import("@/views/children/CRUDS/PermissionLabels/ShowAll.vue"),
            },
            // Show Single

            // Edit
            {
              path: "edit/:id",
              name: "edit-permission-label",
              component: () =>
                import("@/views/children/CRUDS/PermissionLabels/Form.vue"),
              props: true,
            },
            // Edit
            {
              path: "add",
              name: "add-permission-label",
              component: () =>
                import("@/views/children/CRUDS/PermissionLabels/Form.vue"),
            },
          ],
        },
        // ******* End  roles *******
        // ******* Start  roles *******
        {
          path: "/roles",
          component: () => import("@/views/children/CRUDS/Roles/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "all-roles",
              component: () =>
                import("@/views/children/CRUDS/Roles/ShowAll.vue"),
            },
            // Show Single

            // Edit
            {
              path: "edit/:id",
              name: "edit-role",
              component: () => import("@/views/children/CRUDS/Roles/Form.vue"),
              props: true,
            },
            // Edit
            {
              path: "add",
              name: "add-role",
              component: () => import("@/views/children/CRUDS/Roles/Form.vue"),
            },
          ],
        },
        // ******* End  permissions-categories *******
        // ******* Start  permissions-categories *******
        {
          path: "/permission-categories",
          component: () =>
            import("@/views/children/CRUDS/PermissionCategory/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "all-permission-category",
              component: () =>
                import("@/views/children/CRUDS/PermissionCategory/ShowAll.vue"),
            },
            // Show Single

            // Edit
            {
              path: "edit/:id",
              name: "edit-permission-category",
              component: () =>
                import("@/views/children/CRUDS/PermissionCategory/Form.vue"),
              props: true,
            },
            // Edit
            {
              path: "add",
              name: "add-permission-category",
              component: () =>
                import("@/views/children/CRUDS/PermissionCategory/Form.vue"),
            },
          ],
        },
        // ******* End  permissions-categories *******

        // ******* Start Live  Chat*******
        // {
        //   path: "/chats/:id?",
        //   component: () => import("@/views/children/Chat/Index.vue"),
        //   name: "chats",
        //   props: true,
        // },
        // ******* End  Live  Chat *******

        // ============================== End CRUDS ==============================

        // ========== Start StaticScreens ==========
        // {
        //   path: "/static-screens",
        //   name: StaticScreens,
        //   component: StaticScreens,
        // },
        // ========== End StaticScreens ==========

        // ========== Start Settings ==========
        {
          path: "/settings",
          component: () => import("../views/children/Settings/Home.vue"),
          children: [
            {
              path: "",
              name: "mainSettings",
              component: () => import("../views/children/Settings/General.vue"),
            },
            // {
            //   path: "social",
            //   name: "socialSettings",
            //   component: () => import("../views/children/Settings/Social.vue"),
            // },
            // {
            //   path: "sms",
            //   name: "sms",
            //   component: () =>
            //     import("../views/children/Settings/SmsServices.vue"),
            // },
            // {
            //   path: "order-setting",
            //   name: "order-setting",
            //   component: () => import("../views/children/Settings/Orders.vue"),
            // },
            // {
            //   path: "app-links",
            //   name: "app-links",
            //   component: () =>
            //     import("../views/children/Settings/AppLinks.vue"),
            // },
          ],
        },
        // ========== End Settings ==========

        // ========== Start Profiles ==========
        {
          path: "/profile",

          component: () => import("@/views/children/adminProfile/Home.vue"),
          children: [
            {
              path: "show",
              name: "showProfile",
              component: () => import("@/views/children/adminProfile/Show.vue"),
            },
            {
              path: "edit",
              name: "editProfile",
              component: () => import("@/views/children/adminProfile/Form.vue"),
            },
            {
              path: "password",
              name: "changePassword",
              component: () =>
                import("@/views/children/adminProfile/ChangePassword.vue"),
            },
          ],
        },
        // ========== End Profiles ==========

        // ========== Start Reference==========
        // {
        //   path: "/reference",
        //   name: "Reference",
        //   component: Reference,
        // },
        // ========== End Reference ==========

        // ========== Start Chat ==========
        // {
        //   path: "/financial_Cancels",
        //   name: "FinancialCancels",
        //   component: () =>
        //     import("../views/children/FinancialCancels/Home.vue"),
        // },
        // ========== Start Chat ==========

        // ========== End Chat ==========

        // ========== Start DriversTracking ==========
        // {
        //   path: '/drivers_tracking',
        //   name: 'DriversTracking',
        //   component: DriversTracking,
        // },
        // ========== End DriversTracking ==========
      ],
    },

    {
      path: "/permission-required",
      name: "permission-required",
      component: () => import("../views/PermissionRequired.vue"),
    },

    //  =========== Start Redirects ===============
    {
      path: "/countries",

      redirect: () => {
        return { path: "/countries/show-all" };
      },
    },
    {
      path: "/about",

      redirect: () => {
        return { path: "/about/show-all" };
      },
    },
    {
      path: "/terms",

      redirect: () => {
        return { path: "/terms/show-all" };
      },
    },
    {
      path: "/privacy",

      redirect: () => {
        return { path: "/privacy/show-all" };
      },
    },
    {
      path: "/about/add",

      redirect: () => {
        return { path: "/about/show-all" };
      },
    },
    {
      path: "/terms/add",

      redirect: () => {
        return { path: "/terms/show-all" };
      },
    },
    {
      path: "/privacy/add",

      redirect: () => {
        return { path: "/privacy/show-all" };
      },
    },
    //  =========== End  Redirects ===============
    // Auth
    {
      path: "/login",
      name: "Login",
      component: Login,
    },

    // NotFound
    { path: "/:notFound(.*)", name: "NotFound", component: NotFound },
  ],
});

const TOKEN = store.getters["auth_module/currentUser"].token;

router.beforeEach((to, _2, next) => {
  if (to.name !== "Login" && !TOKEN) {
    next({ name: "Login" });
  } else if (to.name == "Login" && TOKEN) {
    next("/");
  } else {
    next();
  }
});

Vue.use(VueRouter);
export default router;
