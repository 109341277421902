<template>
  <div>
    <Breadcrumb :items="items" />
    <Loader v-if="loading && editMode"></Loader>
    <div v-else>
      <noData v-if="noData" />
      <div class="form countryForm custom_card" v-else>
        <ValidationObserver ref="formCoupon" @submit.prevent="submit">
          <div class="row">
            <!-- Start:: Group Service -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.group_services')"
              class="col-lg-4"
              rules="required|min:2|max:250"
            >
              <base-select-input
                :name="$t('labels.group_services')"
                v-model="data.group_services"
                getUrl="group_service_not_paginated"
                :placeholder="$t('labels.group_services')"
                @input="getServices"
              ></base-select-input>
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: Group Service -->
            <!-- Start::  Service -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.service')"
              class="col-lg-4"
              rules="required|min:2|max:250"
            >
              <base-select-input
                :name="$t('labels.service')"
                v-model="data.service"
                :static="true"
                :staticItems="services"
                :placeholder="$t('labels.service')"
              ></base-select-input>
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: Service -->
            <!-- Start:: Provider -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.provider')"
              class="col-lg-4"
              rules="required|min:2|max:250"
            >
              <base-select-input
                :name="$t('labels.provider')"
                v-model="data.provider"
                getUrl="provider_not_paginated"
                :placeholder="$t('labels.provider')"
                @input="getProviderServices"
              ></base-select-input>
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: Provider -->

            <!-- Start::  Service -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.providerServices')"
              class="col-lg-4"
              rules="required|min:2|max:250"
            >
              <base-select-input
                :name="$t('labels.providerServices')"
                v-model="data.provider_service"
                :static="true"
                :staticItems="providerServices"
                :placeholder="$t('labels.providerServices')"
              ></base-select-input>
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: Service -->

            <!-- Start:: UsageTimes -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.number_of_points')"
              class="col-lg-4"
              rules="required|min_value:1"
            >
              <base-input
                :name="$t('labels.number_of_points')"
                type="number"
                @keypress="preventMinus"
                :placeholder="$t('labels.number_of_points')"
                v-model.number="data.number_of_points"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: UsageTimes -->

            <!-- Start:: Min Purchase -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.number_of_use_for_user')"
              class="col-lg-4"
              rules="required|min_value:1"
            >
              <base-input
                :name="$t('labels.number_of_use_for_user')"
                type="number"
                @keypress="preventMinus"
                :placeholder="$t('labels.number_of_use_for_user')"
                v-model.number="data.number_of_use_for_user"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: Min Purchase -->

            <!-- Start::  Start At -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.start_at')"
              class="col-lg-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.start_at')"
                type="date"
                :allowedDate="new Date()"
                v-model="data.start_at"
                :acceptToUp="true"
                :placeholder="$t('labels.start_at')"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>

            <!-- End::  Start At -->
            <!-- <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.start_at_time')"
              class="col-lg-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.start_at_time')"
                type="time"
                v-model="data.start_time"
                :placeholder="$t('labels.start_at_time')"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider> -->
            <!-- End::  Start At -->

            <!-- Start::  end At -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.end_at')"
              class="col-lg-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.end_at')"
                type="date"
                :acceptToUp="true"
                v-model="data.end_at"
                :allowedDate="data.start_at ? data.start_at : new Date()"
                :placeholder="$t('labels.end_at')"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>

            <!-- End::  end At -->
            <!-- <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.end_at_time')"
              class="col-lg-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.end_at_time')"
                type="time"
                v-model="data.end_time"
                :placeholder="$t('labels.end_at_time')"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider> -->
            <!-- End::  end At -->

            <!-- Start:: Checks -->
            <div class="col-lg-12 py-0">
              <div class="divider">
                <h4>{{ $t("labels.status") }}</h4>
                <hr
                  role="separator"
                  aria-orientation="horizontal"
                  class="v-divider theme--dark"
                />
              </div>
            </div>
            <v-checkbox
              v-model="data.is_active"
              :label="$t('labels.is_active')"
            ></v-checkbox>
          </div>

          <div class="button_wrapper center">
            <v-btn
              type="submit"
              @click="submit"
              :disabled="loading"
              :loading="loading"
            >
              {{ $t("submit") }}
            </v-btn>
          </div>
          <!-- <base-button type="submit" @click="submit" class="center"></base-button> -->
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
// import MapWrite from "../../../../components/Maps/MapWrite.vue";

export default {
  // components: { MapWrite },
  props: ["id"],
  data() {
    return {
      noData: false,
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.points.title"),
          disabled: false,
          href: "/points/show-all",
        },
        {
          text: this.$t(`breadcrumb.points.${this.id ? "edit" : "add"}`),
          disabled: true,
          href: "",
        },
      ],
      loading: false,
      data: {
        code: null,
        start_at: null,
        start_time: null,
        end_at: null,
        max_amount: null,
        end_time: null,
        number_of_points: null,
        number_of_use_for_user: null,
        is_active: false,
        group_services: null,
        provider_service: null,
        avatar: {
          mainMedia: null,
          preview: null,
          image: null,
          mainPreviewUrl: null,
        },
        avatarEn: {
          mainMedia: null,
          preview: null,
          image: null,
          mainPreviewUrl: null,
        },
        inner_media_ar: {
          mainMedia: null,
          preview: null,
          image: null,
          mainPreviewUrl: null,
        },
        inner_media_en: {
          mainMedia: null,
          preview: null,
          image: null,
          mainPreviewUrl: null,
        },
      },
      discountTypes: [
        {
          id: "amount",
          name: this.$t("monyAmount"),
        },
        {
          id: "percentage",
          name: this.$t("percentage", { name: " " }),
        },
      ],
      services: [],
      providerServices: [],
    };
  },
  methods: {
    getEditData() {
      this.noData = false;
      this.loading = true;
      this.axios({
        method: "GET",
        url: `loyalities/${this.id}`,
      })
        .then((res) => {
          this.loading = false;

          this.data = {
            group_services: res.data.data?.service?.group_service,
            provider: res.data.data.provider,
            number_of_use_for_user: res.data.data.number_of_use_for_user,
            number_of_points: res.data.data.number_of_points,
            service: res.data.data.service,
            minPurchase: res.data.data.min_purchase,
            provider_service: res.data.data.provider_service,
            user_usage_count: res.data.data.user_usage_count,
            start_at: new Date(res.data.data.start_time).toLocaleDateString(
              "en-CA"
            ),
            end_at: new Date(res.data.data.end_time).toLocaleDateString(
              "en-CA"
            ),
            // start_time: new Date(res.data.data.start_date).toLocaleTimeString(
            //   "en-US",
            //   { hour12: false }
            // ),
            // end_time: new Date(res.data.data.end_date).toLocaleTimeString(
            //   "en-US",
            //   { hour12: false }
            // ),

            // Checks
            is_active: res.data.data.is_active,
            // is_city_to_city_active: res.data.data.is_city_to_city_active,
            // is_trip_active: res.data.data.is_trip_active,
            // is_package_delivering_active:
            //   res.data.data.is_package_delivering_active,

            // discountAppliedToJourney: res.data.data.applies_to_trip_no,

            // nameAr: res.data.data.ar.title,
            // nameEn: res.data.data.en.title,
            // descAr: res.data.data.ar.desc,
            // descEn: res.data.data.en.desc,

            // avatar: {
            //   mainMedia: null,
            //   preview: res.data.data.ar.media,
            //   image: null,
            //   mainPreviewUrl: null,
            // },
            // avatarEn: {
            //   mainMedia: null,
            //   preview: res.data.data.en.media,
            //   image: null,
            //   mainPreviewUrl: null,
            // },
            // inner_media_ar: {
            //   mainMedia: null,
            //   preview: res.data.data.ar.inner_media,
            //   image: null,
            //   mainPreviewUrl: null,
            // },
            // inner_media_en: {
            //   mainMedia: null,
            //   preview: res.data.data.en.inner_media,
            //   image: null,
            //   mainPreviewUrl: null,
            // },
          };
          if (this.data.group_services) {
            this.getServices();
          }
          if (this.data.provider) {
            this.getProviderServices();
          }
        })
        .catch((err) => {
          this.$iziToast.error({
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
          this.noData = true;
          this.loading = false;
        });
    },
    async uploadImage(event, modal, varName) {
      if (event) {
        this.btnLoading = true;
        const data = new FormData();
        data.append("file", event);
        data.append("attachment_type", "image");
        data.append("model", modal);

        await this.axios({
          method: "POST",
          url: "attachments",
          data: data,
        })
          .then((res) => {
            this.data[varName].image = res.data.data;
            this.data[varName].preview = URL.createObjectURL(event);
            this.btnLoading = false;
          })
          .catch((err) => {
            this.$iziToast.error({
              title: this.$t("validation.error"),
              message: err.response.data.message,
            });

            this.btnLoading = false;
          });
      }
    },
    submit() {
      this.$refs.formCoupon.validate().then((d) => {
        if (d) {
          this.loading = true;
          const data = new FormData();
          if (this.data.start_at) {
            data.append(
              "start_time",
              `${this.$moment(this.data.start_at).format("YYYY-MM-DD")}`
            );
          }
          if (this.data.end_at) {
            data.append(
              "end_time",
              `${this.$moment(this.data.end_at).format("YYYY-MM-DD")}`
            );
          }
          data.append(
            "number_of_use_for_user",
            this.data.number_of_use_for_user
          );
          data.append("group_serviec_id", this.data.group_services?.id);
          data.append("number_of_points", this.data.number_of_points);
          data.append("service_id", this.data.service?.id);
          data.append("provider_id", this.data.provider?.id);
          data.append("provider_service_id", this.data.provider_service?.id);
          data.append("is_active", +this.data.is_active);
          // data.append("ar[title]", this.data.nameAr);
          // data.append("en[title]", this.data.nameEn);
          // data.append("ar[desc]", this.data.descAr);
          // data.append("en[desc]", this.data.descEn);

          // if (this.data.avatar.image) {
          //   data.append("ar[media]", this.data.avatar.image);
          // }
          // if (this.data.avatarEn.image) {
          //   data.append("en[media]", this.data.avatarEn.image);
          // }
          // if (this.data.inner_media_ar.image) {
          //   data.append("ar[inner_media]", this.data.inner_media_ar.image);
          // }
          // if (this.data.inner_media_en.image) {
          //   data.append("en[inner_media]", this.data.inner_media_en.image);
          // }

          if (this.id) {
            data.append("_method", "PUT");
          }
          let url = "";
          if (this.id) {
            url = `loyalities/${this.id}`;
          } else {
            url = "loyalities";
          }

          let message = this.id
            ? this.$t("editSuccess")
            : this.$t("addSuccess");

          this.axios({
            method: "POST",
            url: url,
            data: data,
          })
            .then(() => {
              this.$iziToast.success({
                title: this.$t("success"),
                message: message,
              });
              this.loading = false;
              this.$router.push("/points/show-all");
            })
            .catch((err) => {
              this.loading = false;
              this.$iziToast.error({
                title: this.$t("error"),
                message:
                  err.response.data.message ?? err.response.data.messages,
              });
            });
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      });
    },
    getServices() {
      this.axios({
        method: "GET",
        url: `group_services/${this.data.group_services?.id}/services`,
      }).then((res) => {
        this.services = res.data.data;
      });
    },
    getProviderServices() {
      this.axios({
        method: "GET",
        url: `services/${this.data.service?.id}/provider-services`,
        params: { provider_id: this.data.provider?.id },
      }).then((res) => {
        this.providerServices = res.data.data;
      });
    },
  },
  computed: {
    editMode() {
      return !!this.id;
    },
  },

  created() {
    this.control = this.$permission.setControls(this.$route.path);
    if (this.id) {
      this.getEditData();
    }
  },
};
</script>
