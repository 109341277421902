import axios from 'axios'

export default {
  getChats(context) {
    axios.get('support_chats').then((res) =>
      context.commit(
        'setChats',
        res.data.data.map((el) => ({
          ago_time: el.ago_time,
          created_at: el.created_at,
          id: el.id,
          is_read: el.is_read,
          last_message: el.last_message,
          owner: el.owner,
          online: false,
          last_message_created_at: el.last_message_created_at,
          last_message_created: new Date(el.last_message_created).getTime(),
        })),
      ),
    )
  },
}
